import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@layout/DefaultPageContainer"));

const PageHero = loadable(() => import("@organisms/PageHero"));
const ArticleList = loadable(() => import("@organisms/ArticleList"));
const EndofPageCta = loadable(() => import("@organisms/EndofPageCta"));

const HomePage = ({ data, pageContext, ...rest }) => {
  const { hero, cta, articles, enableFeaturedArticle } = data;
  return (
    <PageContainer {...rest} flex={false}>
      <PageHero {...hero} patternColor="white" />
      <ArticleList
        enableFeaturedArticle={enableFeaturedArticle}
        articles={articles}
        type={pageContext.category || "all"}
      />
      <EndofPageCta {...cta} />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
