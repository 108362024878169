import React from "react";
import { graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
import resolveArticle from "@dataResolvers/resolveArticle";
import PageTemplate from "@pageTemplates/ArticlesIndexPage";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query ArticlesIndexQuery {
    craft {
      entries(section: "articles") {
        title
        postDate
        uid
        url
        slug
        ... on Craft_articles_external_Entry {
          description: metaDescription
          articleCategory {
            uid
            title
            slug
          }
          topics {
            uid
            title
            slug
          }
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          authors {
            ... on Craft_people_default_Entry {
              name: title
              uid
              slug
              headshot: image0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
        }
        ... on Craft_articles_default_Entry {
          description: metaDescription
          articleCategory {
            uid
            title
            slug
          }
          topics {
            uid
            title
            slug
          }
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          authors {
            ... on Craft_people_default_Entry {
              name: title
              uid
              slug
              headshot: image0 {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
        }
      }
      entry(section: "articlesIndex") {
        title
        ... on Craft_articlesIndex_articlesIndex_Entry {
          enableFeaturedArticle: boolean0
          featuredArticle {
            uid
          }
          # Hero
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroImage: image0 {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          # Meta
          slug
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries, entry }) => {
  const {
    // Hero
    heroHeading,
    heroDescriptor,
    heroButtons,
    heroImage,
    // Meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
    slug,
    enableFeaturedArticle,
    featuredArticle: fArticle,
  } = entry;

  let featuredArticle;
  let filteredArticles = entries.map(resolveArticle);
  // .filter(e => e.uid !== featuredArticle?.[0]?.uid);
  if (fArticle[0]) {
    featuredArticle = filteredArticles.find(f => f.uid === fArticle[0].uid);
    if (featuredArticle) {
      filteredArticles = [
        featuredArticle,
        ...filteredArticles.filter(f => f.uid !== featuredArticle.uid),
      ];
    }
  }

  // const featuredArticle = fArticle.find(e=>) : resolvedArticles[0] || {}

  return {
    hero: {
      heading: heroHeading,
      descriptor: heroDescriptor,
      buttons: heroButtons,
      image: resolveImage(heroImage),
      color: "teal",
    },
    enableFeaturedArticle,
    articles: filteredArticles,
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
      slug,
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="ArticlesIndexQuery"
    {...props}
  />
);

export default Page;
